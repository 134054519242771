import {generateAssets} from 'src/shared/responsiveAssetsLoader.js';

const assetsDir = 'games/MasterThief/';
const gameAssets = {
    audio: {
        theme: 'theme.mp3',
        gamePlay: 'gameplay.mp3',
        effectCorrect: 'effect-press-correct.mp3',
        effectPresentation: 'effect-press-presentation.mp3',
        effectIncorrect: 'effect-press-incorrect.mp3',
        effectTrialCorrect: 'effect-trial-correct.mp3',
        roundEnd: 'round-end.mp3'
    },
    images: {
        splash: 'splash.webp',
        background: 'background.webp',
        backgroundPlay: 'background-play.webp',
        backgroundSafe: 'background-safe.webp',
        buttonOff: 'btn-off.webp',
        buttonRed: 'btn-red.webp',
        buttonGreen: 'btn-green.webp',
        buttonYellow: 'btn-yellow.webp',
        buttonBlue: 'btn-blue.webp',
        buttonOrange: 'btn-orange.webp',
        buttonPurple: 'btn-purple.webp',
        buttonGrey: 'btn-grey.webp',
        incorrect: 'button-incorrect.webp',
        wrongOrder: 'button-wrong-order.webp',
        modal: 'modal.webp',
        gem1: 'jewel_1.webp',
        gem2: 'jewel_2.webp',
        gem3: 'jewel_3.webp',
        gem4: 'jewel_4.webp',
        gem5: 'jewel_5.webp',
        gems: [
            'jewel_1.webp',
            'jewel_2.webp',
            'jewel_3.webp',
            'jewel_4.webp',
            'jewel_5.webp'
        ]
    }
};

export default generateAssets(assetsDir, gameAssets);