//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from '../../../../../shared/GlobalMixins.js';
import GamePlayMixin from '../../../../../shared/GamePlayMixin.js';
import InitializeGameMixin from "src/shared/InitializeGameMixin";
import { getAssetSizeSuffix } from 'src/shared/responsiveAssetsLoader.js';

import Animation from '../../../../../shared/Animation.js';
import gamePlayCopy from '../data/copy.js';

export default {
  name: 'Play',
  mixins: [GlobalMixin, GamePlayMixin, Animation, InitializeGameMixin],
  components: {
    Timer: () => import(/* webpackChunkName: "timer" */ '../../../Common/Timer')
  },
  data() {
    return {
      activeButtons: [],
      buttonReaction: null,
      buttonReactionClass: null,
      buttons: [],
      buttonSequenceIndex: 0,
      container: null,
      buttonScreen: null,
      expectedAnswers: [],
      clickedButtons: [],
      correctThisTrial: 0,
      hintedButton: false,
      hintMode: true,
      incorrectAnswers: false,
      nextTrial: false,
      playDisabled: false,
      endRoundText: '',
      playStart: null,
      totalCorrect: 0,
      shownGems: [],
      totalAnswers: 0,
      totalAnswersPerRound: 0,
      answersPerTrial: [],
      wrongOrder: false,
      showSafe: true,
      totalExpectedAnswers: 0,
      grid: {
        width: 7,
        height: 5
      },
      gridLastRow: [],
      offsetGrid: [],
      offsetGridVals: [],
      scoring: {
        correctHint: 50,
        incorrectHint: -20,
        correctResponse: 100,
        incorrectResponse: 50,
        orderBonus: 50,
        trial: 50
      },
      randomNumbers: {
        numberOfColors: null,
        totalTPForColor: null,
        rowLength: null,
        startVal: null,
        getDisplayInterval: null
      }
    };
  },
  methods: {
    async initializeGamePlay() {
      // don't let them play until we get past the instruction modal
      this.setGameExited(false);
      this.setTimer('false');
      this.setShowTimer(false);
      this.setScore(0);
      this.resetTrialsCorrect();
      this.container = this.$refs.gameplay;
      this.buttonScreen = this.$refs.buttonScreen;
      this.playDisabled = true;
      this.incorrectAnswers = false;

      // reveal selected buttons for this session
      console.log('initializing gameplay');
      // loop for each round
      // get the user level, setup grid for first trial/round
      await this.getSettings(); // this will actually get the user, grid data
      await this.setupButtons();
      this.totalAnswersPerRound += this.activeButtons.length;
      this.answersPerTrial.push(this.activeButtons.length);

      // get half of screen height
      let halfScreen = window.innerHeight / 3;
      // then we go to this main container
      await this.animation.to(this.container, 2, {y: -halfScreen , ease: this.tweenExpo.easeOut});
      this.showSafe = true;
      await this.showRandomGems();
      await this.sleep(1200);
      await this.animation.to(this.buttonScreen, 1.5, {x: 0, ease: this.tweenExpo.easeOut});
      this.showSafe = false;
      await this.sleep(100); // wait

      await this.showGameplayHints();
      await this.sleep(999999); // set the play start time
      // show translate and respond dialog, pick last (or multiples, if level dictates)
      // we need to wait until they click dialog button before we advance to next stage
    },
    async getSettings(firstPlay = true) {
      let gameData = await this.startGamePlay({
        gameId: this.gameId, playId: this.play_id
      });

      this.setGameLevelData(gameData);
      this.setRoundId(gameData.round_id);
      this.setLevel(this.gameLevelData.start_level);
      this.setRounds(6);
      this.setTrials(2);
    },
    async initializeEmptyButtons() {
      // setup blank grid
      this.buttons = [];
      for (let i = 0; i < 24; i++) {
        this.buttons.push({
          clicked: false,
          correct: false,
          reaction: null
        });
      }
    },
    /**
     * Setup the button grid for the current round.
     * @returns {Promise<unknown>}
     */
    async setupButtons() {
      // build the play grid per legacy game
      this.clearCorrectAnswers();
      this.activeButtons = [];

      this.clearAllReactions();

      let offsetGrid = [],
          offsetVals = [],
          displaySequence = [],
          totalTouchpads = [],
          lastRow = [];

      let numberOfColors = this.randomNumbers.numberOfColors
          .setMinMax(
              this.gameLevelData.game_settings.color_range_min,
              this.gameLevelData.game_settings.color_range_max
          ).getRandomIntegerInRange();

      let colors = this.getSample(this.colorValues, numberOfColors);

      for (const c of colors) {
        let totalTPForColor = this.randomNumbers.totalTPForColor
            .setMinMax(
                this.gameLevelData.game_settings.touchpad_per_color_min,
                this.gameLevelData.game_settings.touchpad_per_color_max
            ).getRandomIntegerInRange();
        totalTouchpads = totalTouchpads.concat(Array(totalTPForColor).fill({color: c}));
      }

      totalTouchpads = this.shuffleArray(totalTouchpads);

      for (let y = 0; y < this.grid.height; y++) {
        let rowLength = this.randomNumbers.rowLength
            .setMinMax(
                this.gameLevelData.game_settings.row_len_min,
                this.gameLevelData.game_settings.row_len_max
            ).getRandomIntegerInRange();


        let rowRange;
        do {
          const startVal = this.randomNumbers.startVal
              .setMinMax(0, this.grid.width - rowLength)
              .getRandomIntegerInRange();

          rowRange = this.range(startVal, startVal + rowLength - 1);
        } while (y > 0 && this.isEmpty(this.intersection([rowRange, lastRow])));

        Array.from({length: this.grid.width}, (_, x) => {
          const isOffset = rowRange.includes(x);
          const theObj = {x, y, idx: offsetGrid.length, show: isOffset, correct: false};

          if (isOffset) {
            offsetVals.push(theObj);
          }
          //tag grid elements that are contained within that range
          offsetGrid.push(theObj);
        });

        lastRow = rowRange;
      }

      this.buttons = offsetGrid;

      let shuffledOffsetValues = this.shuffleArray(offsetVals);

      totalTouchpads.forEach(function (touch, idx) {
        let currentOffset = shuffledOffsetValues[idx];
        currentOffset.correct = true;
        currentOffset.color = touch.color;

        displaySequence.push(
            {
              idx: currentOffset.idx,
              x: currentOffset.x,
              y: currentOffset.y,
              color: currentOffset.color.name,
              colorObj: currentOffset.color
            });
      });

      this.activeButtons = offsetVals.filter(display => display.correct === true);

      let hints = this.shuffleArray(colors.map(a => a.name));
      // should really collapse all of these loops into one or two big ones
      offsetGrid.forEach(function (button, index, offsetGrid) {
        let matchedButton = displaySequence.find(display => display.idx === index);

        if (matchedButton !== undefined) {
          offsetGrid[index].color = matchedButton.color;
          offsetGrid[index].hex = matchedButton.colorObj.hex;
        }
      });

      this.buttons = offsetGrid;
      this.setHints(hints);
      this.setTextPlayHints('');
      let activeButtonsClone = [...this.activeButtons];

      // retrieve only the correct active buttons via the hints array
      let buttonsOrderedByHintColor = activeButtonsClone.filter(function (el) {
        return hints.includes(el.color);
      });

      // then we need to reorder by the hint color to get the correct answer sequence. the overall shown order should be preserved
      buttonsOrderedByHintColor = buttonsOrderedByHintColor.sort(function (a, b) {
        return hints.indexOf(a.color) - hints.indexOf(b.color);
      });

      this.expectedAnswers.push(...buttonsOrderedByHintColor);
      this.totalExpectedAnswers = this.expectedAnswers.length;
      if (this.buttons.length > 0) {
        return true;
      }

      throw new Error('No active buttons were set for this round.');
    },
    async showRandomGems() {
      const min = 2, max = 3;
      this.shownGems = [];
      let numberOfGems = Math.floor(Math.random() * (max - min + 1)) + min;
      let randomGems = this.shuffleArray(this.assets.images.gems);

      let i = 0;
      while (i < numberOfGems) {
        if (randomGems[i] !== undefined)
          this.shownGems.push(randomGems[i]);
        await this.sleep(this.randomInRange(200, 600));
        i++;
      }
    },
    async startGamePlayMode() {
      this.playDisabled = false;
      this.modalMessageReset();
      // really all that should happen here is that we start the timer. we should also
      // react to timer being done

      this.trialStart = new Date().getTime();
    },
    async showActiveButtonByIndex(index) {
      this.setTimerStopAfter(500);
      await this.startTimer(200);

      let button = this.activeButtons[index];
      this.hintedButton = button.idx;;
    },
    /**
     * Show the button sequence for the current round.
     * @returns {Promise<unknown>}
     */
    async showButtonSequence() {
      for (let button of this.activeButtons) {
          if (this.gameExited) {
            this.unloadMusic();
            resolve(true);
            break;
          }

          this.hintedButton = button.idx;

          await this.sleep(3000);
        }

        this.hintedButton = false;
    },
    /**
     * Show the gameplay hints for the current round.
     * @param count
     * @returns {Promise<void>}
     */
    async showGameplayHints(count = 1) {
      // show hints dependent on Level
      this.playStart = new Date().getTime();
      this.hintMode = true;
      this.setMusic(this.assets.audio.gamePlay, true);
      this.buttonSequenceIndex = 0;
      await this.showActiveButtonByIndex(this.buttonSequenceIndex);

      if (this.gameExited) return; // make sure if the user exits at this stage, we don't keep going
      await this.sleep(1000);
    },
    showPlayModeModal() {
      this.hintedButton = false;
      this.stopAndHideTimer();
      this.setModalMessage({
        title: gamePlayCopy.gamePlay.playTitle,
        body: this.formatText(gamePlayCopy.gamePlay.playInstructions, {count: this.activeButtons.length}),
        actionButton: {action: this.startGamePlayMode, text: gamePlayCopy.gamePlay.playButton}
      });

      this.hintMode = false;
    },
    /**
     * Get the proper image for the button based on its state.
     * @param the_button
     * @param index
     * @returns {string|*}
     */
    getButtonState(the_button, index) {
      if (the_button.reaction === 'incorrect') return this.assets.images.incorrect;
      if (the_button.reaction === 'wrongOrder') return this.assets.images.wrongOrder;

      const sizeSuffix = getAssetSizeSuffix();
      if (this.hintedButton === index || the_button.reaction === 'correct') {
        return this.CDN + 'games/MasterThief/images/'+sizeSuffix+'btn-' + the_button.color + '.webp';
      }

      return this.assets.images.buttonOff;
    },
    /**
     * Set the reaction for a button.
     * @param button
     * @param reaction
     * @param sleep
     * @returns {Promise<void>}
     */
    async setButtonReaction(button, reaction, sleep) {
      this.buttons[button].reaction = reaction;
      this.hintedButton = this.buttons[button].idx;

      if (sleep) {
        await this.sleep(sleep);
        this.hintedButton = false;
        if (reaction === 'correct') this.buttons[button].reaction = null;
      }
    },
    async moveGems(moveGems, increaseScore = false) {
      for (let gemIndex = 0; gemIndex < this.shownGems.length; gemIndex++) {
        await this.animation.to(this.$refs['gem' + gemIndex], 0.5, {
          x: moveGems,
          y: moveGems,
          ease: this.tweenExpo.easeOut
        });
        if (increaseScore) {
          this.increaseScore(increaseScore[gemIndex]);
        }
        await this.sleep(100);
      }
    },
    async handleHintMode(button) {
      if (button === this.hintedButton) {
        this.setEffect(this.assets.audio.effectPresentation);
        this.increaseScore(this.scoring.correctHint);
        if (this.activeButtons.length > this.buttonSequenceIndex + 1) {
          this.buttonSequenceIndex++;
          await this.showActiveButtonByIndex(this.buttonSequenceIndex);
        } else {
          await this.sleep(500);
          this.showPlayModeModal();
        }
      }
    },
    async handlePlayMode(currentButton, incorrect, button) {
    this.stopTimer = true;
    this.clickedButtons.push(button);

    if (!this.playDisabled) {
      incorrect = await this.processButton(currentButton, incorrect, button);
      this.totalAnswers++;
    }

    this.setShowTimer(false);

    if (this.totalAnswers === this.activeButtons.length || incorrect) {
      await this.processTrialEnd(incorrect);
      await this.determineNextGamePhase();
    }
  },

  async processButton(currentButton, incorrect, button) {
    if (!currentButton.correct) {
      incorrect = await this.handleIncorrectButton(button);
    } else {
      incorrect = await this.handleCorrectButton(currentButton, button);
    }
    return incorrect;
  },

  async handleIncorrectButton(button) {
    console.log('wrong answer');
    this.incorrectAnswers = true;
    this.decreaseScore(this.scoring.incorrectResponse);
    this.setEffect(this.assets.audio.effectIncorrect);
    await this.setButtonReaction(button, 'incorrect', 1000);
    return true;
  },

  async handleCorrectButton(currentButton, button) {
    console.log('active button clicked');
    let incorrect = false;

    if (currentButton.idx === this.expectedAnswers[0].idx) {
      await this.handleCorrectOrderButton(button);
    } else {
      incorrect = await this.handleIncorrectOrderButton(button);
    }

    return incorrect;
  },

  async handleCorrectOrderButton(button) {
    console.log('correct answer');
    this.addToCorrectAnswers(button);
    this.correctThisTrial++;
    if (this.correctThisTrial === this.totalExpectedAnswers) {
      console.log("correct trial");
      this.updateTrialsCorrect(this.trialsCorrect + 1);
    }
    this.setEffect(this.assets.audio.effectCorrect);
    this.expectedAnswers.shift();
    await this.setButtonReaction(button, 'correct');
    this.hintedButton = false;
  },

  async handleIncorrectOrderButton(button) {
    console.log('wrong answer - order');
    this.incorrectAnswers = true;
    this.decreaseScore(this.scoring.incorrectResponse);
    this.setEffect(this.assets.audio.effectIncorrect);
    await this.setButtonReaction(button, 'wrongOrder', 3000);
    return true;
  },

  async processTrialEnd(incorrect) {
    console.log('trial finished!');
    this.expectedAnswers.splice(0);
    this.stopMusic();

    if (this.correctThisTrial === this.totalExpectedAnswers) {
      this.increaseTrialsCorrect();
      this.setTrialStatus('correct');
      this.trialWasCorrect = true;
    } else {
      this.setTrialStatus('incorrect');
      this.trialWasCorrect = false;
    }

    if (!incorrect) {
      await this.processCorrectTrialEnd();
    }
  },

  async processCorrectTrialEnd() {
    console.log("safe start");
    await this.sleep(1200);
    this.showSafe = true;
    await this.animation.to(this.buttonScreen, 1.5, {x: -3000, ease: this.tweenExpo.easeOut});
    this.setEffect(this.assets.audio.effectTrialCorrect);

    const timerValue = isNaN(this.timer) ? 0 : this.timer;
    const timerBonus = Math.max(Math.ceil((100 - Math.abs(timerValue)) / 10) * 25, 0);
    const increaseScore = this.scoreUpTrial + timerBonus;
    const increasedScoreSteps = this.calculateIncreasedScoreSteps(increaseScore);
    await this.moveGems(-3000, increasedScoreSteps);

    this.increaseScore(increaseScore);
    await this.sleep(1000);
    console.log('safe end');
  },

  calculateIncreasedScoreSteps(increaseScore) {
    const roundedPart = Math.floor(increaseScore / this.shownGems.length);
    const remainingNumbers = new Array(this.shownGems.length - 1).fill(roundedPart);
    const lastNumber = increaseScore - (roundedPart * (this.shownGems.length - 1));
    return [...remainingNumbers, lastNumber];
  },
    /**
     * Evaluate the click correctness on a play button.
     * @param button
     * @returns {Promise<void>}
     */
    async evaluateClick(button) {
      await this.stopAndHideTimer();

      if (this.buttonDisabled) return; //avoid button click
      this.buttonDisabled = true;
      let currentButton = this.buttons[button];
      if (!currentButton.show || this.clickedButtons.includes(button)) {
        this.buttonDisabled = false;

        return false; // if the button is not active, or if it has already been clicked, do nothing
      }

      let incorrect = false;

      if (this.hintMode) {
        // if this is hint mode, they should only be able to click the hints. it should give them points
        // otherwise there should be no reaction whatsoever
        await this.handleHintMode(button);
      } else {
        // this is not hint mode, we are playing
        await this.handlePlayMode(currentButton, incorrect, button);
      }
      this.buttonDisabled = false;
    },
    /**
     * Determine the next game phase based on current trial and round.
     * @returns {Promise<void>}
     */
    async determineNextGamePhase() {
      console.log('determineNextGamePhase');
      this.stopAndHideTimer();
      // save the trial results
      await this.setGameLevelAndSaveTrialResults();

      if (!this.gamePlayComplete) {
        console.log('gameplay not complete');

        this.remainingTrials ? await this.goToNextTrial() : await this.getRoundScoreAndGoToNext();
      } else {
        console.log('zzz game play complete')
        await this.endRoundScore();
        this.increaseTickets(6);
        this.setTicketEffect();
        this.playDisabled = true;
        await this.updatePlayLevel({
          gameId: this.gameId, play_id: this.play_id, level: Math.max(1, this.level), stopTime: new Date().getTime()
        });

        this.movedUpALevel ? this.showMovedUpALevelModal(true) : this.showEndOfGameModal();
      }
    },
    async getRoundScoreAndGoToNext() {
      await this.endRoundScore();
      await this.goToNextRound();
    },
    /**
     * Go to the next trial and reset appropriate values.
     * @returns {Promise<void>}
     */
    async goToNextTrial() {
      this.playDisabled = true;
      this.clickedButtons = [];
      this.totalExpectedAnswers = 0;
      this.modalMessageReset();
      this.totalAnswers = 0;
      this.totalAnswersPerRound += this.activeButtons.length;
      this.answersPerTrial.push(this.activeButtons.length);
      await this.sleep(800); // so we see stuff before it goes away?

      await this.getSettings(false);

      this.correctThisTrial = 0;

      if (!this.remainingTrials) {
        this.resetTrialsCorrect();
      }

      this.startNewRound();

      await this.setupButtons();
      this.setTimer('false');

      await this.animation.to(this.buttonScreen, 0, {x: -3000, ease: this.tweenExpo.easeOut});
      await this.showRandomGems();
      this.showSafe = true;
      // get half of screen height
      let halfScreen = window.innerHeight / 3;
      await this.animation.to(this.container, 1.5, {y: -halfScreen, ease: this.tweenExpo.easeOut});

      await this.sleep(1200);
      await this.animation.to(this.buttonScreen, 1.5, {x: 0, ease: this.tweenExpo.easeOut});
      this.showSafe = false;
      this.playStart = new Date().getTime(); // start counting the next trial time
      await this.showGameplayHints();
    },
    /**
     * Calculate scoring and leveling based on the number of correct answers in the round.
     */
    async endRoundScore() {
      if (this.allAnswersCorrect) {
        console.log('got all of the answers correct, go up a level');

        await this.goUpLevel();
        this.increaseTickets(3);
        this.setTicketEffect();
      }

      // some of the answers are correct
      if (this.someAnswersCorrect) {
        console.log('some answers are correct, stay at same level');
      }

      if (this.noAnswersCorrect) {
        console.log('all of the answers are wrong, go down a level');
        this.goDownLevel();
      }

      this.incorrectAnswers = false;
      this.totalAnswersPerRound = 0;
      this.answersPerTrial.splice(0);
      await this.updateGameLevel({
        game_id: this.gameId, level: this.level, newTickets: this.tickets
      });
    },
    /**
     * Go to the next round and reset appropriate values.
     */
    async goToNextRound() {

      if (this.allAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.all;
      }

      if (this.someAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.some;
      }

      if (this.noAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.none;
      }

      let trialsCorrect = this.trialStatus.filter(status => status === "correct").length;

      this.setModalMessage({
        title: this.formatText(
            gamePlayCopy.rounds.end,
            {round: this.currentRound},
            false
        ),
        body: this.formatText(this.endRoundText, {correct: trialsCorrect}),
        actionButton: {
          action: this.movedUpALevel ? this.showMovedUpALevelModal : this.goToNextTrial,
          text: gamePlayCopy.rounds.playNext
        }
      });

    },
    clearAllReactions() {
      for (const button of this.buttons) {
        button.reaction = null;
      }
    }
  },
  computed: {
    allAnswersCorrect() {
      return this.trials === this.trialStatus.length && this.trialStatus.every(status => status === "correct");
    },
    someAnswersCorrect() {
      return this.trials === this.trialStatus.length && !this.trialStatus.every(status => status === "correct") && this.trialStatus.includes("correct");
    },
    noAnswersCorrect() {
      return this.trials === this.trialStatus.length && (this.trialStatus.length === 0 || !this.trialStatus.includes("correct"));
    },
    /**
     * Get the display interval for the current round.
     * @returns {number}
     */
    getDisplayInterval() {
      // check if this.gameLevelData.game_settings.display_interval_min is set
      // if not, return 0
      return 0.8;
    }
  },
  created() {
    // initialize any random number generators
    Object.keys(this.randomNumbers).forEach(key => {
      this.randomNumbers[key] = this.createUniqueRandomGenerator();
    });
  },
  async mounted() {
    await this.initializeGamePlay(); // start round 1, trial 1
  }
};
