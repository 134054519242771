export default {
  gamePlay: {
    playTitle: "Code Input",
    playInstructions: "<p>Transmission of safe combination complete.</p><p>Now crack the safe. Enter the passcode in the correct Color Order (top right).</p><p><strong>ADVANCED SAFE CRACKING:</strong></p><p>Some colors may appear more than once. Click those buttons in the order you saw them. But keep following the Color Order.</p>",
    playButton: 'Start the heist!'
  },
  rounds: {
    end: "End of Round {{round}}",
    playNext: "Play next round!",
    all: "Excellent! You got {{correct}} trials correct!",
    some: "Great job! You got {{correct}} trials correct in this round.",
    none: "Good try. Let's keep practicing!"
  },
  gameComplete: {
    playTitle: "Game Completed!",
    playInstructions: "Great work, keep practicing!",
    playButton: 'Return to games'
  },
    levelUp: {
        title: "Amazing job, level up!!!",
        bodyRender: (level) => `Moving to level ${level}, and you got some tickets!`
    },
    instructions: [
        {
            'title': 'Safe Cracking 101',
            'body': 'Every safe has a different unlock code.<br>\n' +
                'You need the right buttons and the right Color Order.\n',
            'buttonText': 'Yes Sir!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Color Code',
            'body': 'We\'ll show you the right buttons. Click them when they blink.<br>\n' +
                'REMEMBER their COLOR.',
            'buttonText': 'Yes Sir!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Recover the Jewels',
            'body': 'Enter the unlock code in the correct Color Order.<br>'+
            'Some buttons may be the same color. Click them in the order that they appeared, but keep following the Color Order.',
            'buttonText': 'Yes Sir!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Ready to start?',
            'body': 'Press yes to start, no to see the instructions again.',
            'buttonText': 'yes',
            'buttonText2': 'no',
            'buttonAction': 'goToPlay',
            'buttonAction2': 'goToBeginning'
        }
    ]
}
